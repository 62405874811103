const { Kushki } = require('@kushki/js');
const {
  celcomKushkiMerchantId,
  isProduction,
} = require('../../../../config/index');

const requestKushkiTokenCredit = (
  montoAPagar,
  moneda,
  nombreTarjeta,
  numeroTarjeta,
  cvcTarjeta,
  mesExpiracion,
  anioExpiracion
) => {
  let kushki = new Kushki({
    merchantId: celcomKushkiMerchantId,
    inTestEnvironment: !isProduction,
    regional: false,
  });

  return new Promise((resolve) => {
    kushki.requestToken(
      {
        totalAmount: montoAPagar,
        currency: moneda,
        card: {
          name: nombreTarjeta,
          number: numeroTarjeta,
          expiryMonth: mesExpiracion,
          expiryYear: anioExpiracion,
          cvv: cvcTarjeta,
        },
      },
      (rsp) => {
        //console.log('respiesta kushki', rsp);
        return resolve(rsp.token);
      }
    );
  });
};

export { requestKushkiTokenCredit };
