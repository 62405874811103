<template>
  <!-- Link de Pago Vista previa-->
  <div class="container">
    <div class="row">
      <div class="col-12 mt-3">
        <b-card body class="text-center card-shadow">
          <b-img
            fluid
            src="../../../assets/images/celcomLogo/logo.png"
            alt="Logo Celcom"
            style="height: 30px"
          />
        </b-card>
      </div>
    </div>

    <div class="row">
      <!-- Nombre y avatar -->
      <div class="col-lg-6 col-sm-12">
        <div class="row">
          <div class="col-12">
            <b-card body class="text-center card-shadow">
              <div class="row">
                <div class="col-lg-2 col-sm-12">
                  <b-avatar
                    size="70px"
                    :src="imagen"
                    variant="light"
                    class="avatarClass"
                  />
                </div>
                <div class="col-lg-10 col-sm-12">
                  <h4 class="font-weight-bolder text-card-detail">
                    {{ nombreProducto }}
                  </h4>
                  <p class="textPromocional text-card-detail">
                    <strong>{{ textoPromocional }}</strong>
                  </p>
                  <span id="descripcion" class="text-card-detail">{{ descripcionProducto }}</span>
                </div>
              </div>
            </b-card>
          </div>
          <div class="col-12">
            <b-card body class="card-shadow">
              <b-form-checkbox
                    v-model="codigoEnabled"
                    class="custom-control-info flex-justify-content-center"
                  >
                    <label class="label-codigos text-card-detail" 
                      >Tengo un codigo de descuento</label
                    >
                  </b-form-checkbox>
             <div class="row align-items-end" v-if="codigoEnabled">
             <h4 class="mb-1 mt-2 ml-1">Código de descuento</h4>
               <div class="col-lg-12 col-sm-12">
              <!--  <b-form-group
                label="Ingrese su código de descuento"
                label-for="codigo"
              > -->
                <b-form-input class="col-lg-6 col-sm-12" id="codigo" v-model="codigo" type="url" placeholder="Ingrese su código" />
            <!--   </b-form-group> -->
             </div>
             <div class="col">
               <b-button
                    id="button-validar"
                    variant="primary"
                    :disabled="codigo.length < 2"

                    class="codigo-button col-lg-6 mt-2"
                  >
                    Validar
                  </b-button>
             </div>
             </div>
             
             
            </b-card>
          </div>
          <div class="col-12">
            <div id="card-monto" class="card-shadow">
              <h4 class="mb-3 mt-1">Resumen de la compra</h4>

              <hr />
              <div class="row mb-1">
                <div class="col-6" v-if="this.linkType == 2">
                  <h6 class="mt-1">Período de cobro</h6>
                </div>
                <div class="col-6" v-if="this.linkType == 2">
                  <h6 class="mt-1">{{ this.textPeriodicidad }}</h6>
                </div>
              </div>

              <div class="row mb-1">
                <div class="col-6">
                  <h6 class="mt-1">Subtotal</h6>
                </div>
                <div class="col-6 text-right">
                  <h6 class="mt-1">{{ total }} {{ moneda.code }}</h6>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <h4 class="mt-1 total-amount">Total</h4>
                </div>
                <div class="col-6 text-right total-amount" v-if="linkType == 2">
                  <h5 class="mt-1">
                    <b
                      >{{ total }} {{ moneda.code }} /
                      {{ textPeriodicidad }}</b
                    >
                  </h5>
                </div>
                <div class="col-6 text-right total-amount" v-if="linkType == 1">
                  <h5 class="mt-1">{{ total }} {{ moneda.code }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Campos formulario -->
      <div class="col-lg-6 col-sm-12">
        <b-card body class="card-shadow">
          <h4 class="mb-2 mt-1">Datos</h4>
          <div>
            <div class="row">
              <div
                v-for="(listItem, index) in this.list2"
                :class="listItem.column_size"
                :key="index"
              >
                <label>{{ listItem.name ? listItem.name : "" }}</label>
                <b-form-input
                  class="mb-1"
                  v-id="index + '-Link'"
                  v-model="listItem.model"
                />
              </div>
            </div>
          </div>

          <hr />

          <h4 class="mt-1 mb-0">Pago</h4>
          <span class="size-span-selecciona-medio"
            >Selecciona una forma de pago</span
          >
          <b-form-group>
            <v-select
              v-model="formaElegida"
              :dir="dir"
              label="text"
              :options="formaSelector"
              :reduce="(Item) => Item.value"
              class="select-size-lg mt-1"
              @input="medioSel()"
              v-on:input="hideInputFormaPago()"
              v-on:close="showInputFormaPago()"
            />
            <label
              v-if="formaCheck"
              class="font-italic"
              style="color: red !important"
              >Debe seleccionar una Forma de pago</label
            >
          </b-form-group>
          <hr class="mt-2" />
          <span class="size-span-selecciona-medio"
            >Selecciona una pasarela de pago</span
          >
          <b-form-group>
            <!-- <v-select v-model="medioElegido" :options="pasarelasSelector" :reduce="item => item.value" label="text" /> -->
            <v-select
              v-model="medioElegido"
              :dir="dir"
              label="text"
              :options="pasarelasSelector"
              :reduce="(Item) => Item.value"
              class="select-size-lg mt-1"
              v-on:input="hideInputFormaPago()"
              v-on:close="showInputFormaPago()"
            />
            <label
              v-if="pasarelaCheck"
              class="font-italic"
              style="color: red !important"
              >Debe seleccionar una Pasarela de pago</label
            >
            <!-- <v-select
              v-model="medioElegido"
              :dir="dir"
              :value="value"
              label="text"
              :options="pasarelasSelector"
              class="select-size-lg mt-1"
              v-on:input="hideInputMedioPago()"
              v-on:close="showInputMedioPago()"
            /> -->
            <!-- label="title" -->
          </b-form-group>

          <hr class="mt-2" />

          <div>
            <b-alert
              v-height-fade.appear
              :show="dismissCountDown"
              dismissible
              class="mb-1"
              variant="warning"
              id="empty-field-popup"
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChanged"
            >
              <div class="alert-body">
                <span class="bold">Advertencia! </span>
                <br />
                <span>Debes completar los campos obligatorios </span>
              </div>
            </b-alert>
          </div>

          <!-- <div v-if="inputsMedioPagoVisible && formaElegida != 3"> -->
          <div
            v-if="
              aceptoTerminosCondiciones &&
              formaElegida != 3 &&
              formaElegida != 4
            "
          >
            <!-- <h5 class="mt-2" v-if="medioElegido == 1 && formaElegida != 2">
              {{ medioElegido == 1 ? "Kushki" : "Stripe" }}
            </h5>
            <h5 class="mt-2" v-if="medioElegido == 2 && formaElegida != 2">
              {{ medioElegido == 2 ? "Stripe" : "Kushki" }}
            </h5> -->
   
              <div class="row credit-card-data card-shadow" v-if="medioElegido == 2 && formaElegida != 2">
                 <div class="row">
                        <h4 class="ml-2 pt-1 col-sm-12 text-card-detail"><b>Datos de la tarjeta</b></h4>
                   
                      </div>
                <div class="col-12">
                  <label><b>Nombre en la tarjeta</b></label>
                  <b-form-input
                    class="mb-1"
                    v-model="nombreTitular"
                    @input="cambia()"
                    id="input-nombreTitular"
                  />
                </div>
                <div class="col-12">
                  <label><b>Numero de Tarjeta</b></label>
                  <input
                    class="mb-1 form-control"
                    id="card-number"
                    v-model="numeroTarjeta"
                    @input="cambia()"
                    type="number"
                    v-mask="'################'"
                  />
                </div>

                <div class="col-6">
                  <label><b>Fecha exp</b></label>
                  <input
                    placeholder="mm/YY"
                    class="mb-1 form-control"
                    @input="cambia()"
                    id="card-expiry"
                    v-model="fechaExpiracion"
                    type="tel"
                    v-mask="'##/##'"
                  />
                </div>
                <div class="col-6">
                  <label><b>CVC</b></label>
                  <input
                    class="mb-1 form-control"
                    id="card-cvc"
                    v-model="cvv"
                    @input="cambia()"
                    type="password"
                    v-mask="'####'"
                  />
                </div>
              </div>
              <div v-if="medioElegido == 1 && formaElegida != 2">
                <div class="row">
                  <div class="col-12">
                    <label>Nombre en la tarjeta</label>
                    <b-form-input
                      class="mb-1"
                      v-model="nombreTitular"
                      id="input-nombreTitular"
                      @input="cambia()"
                      type="text"
                    />
                  </div>
                  <div class="col-12">
                    <label>Número de Tarjeta</label>
                    <b-form-input
                      class="mb-1 control"
                      id="card_number"
                      type="number"
                      @input="cambia()"
                      v-mask="'################'"
                      v-model="numeroTarjeta"
                    />
                  </div>
                  <div class="col-6">
                    <label>Fecha de expiración</label>
                    <b-form-input
                      placeholder="mm/YY"
                      class="mb-1 control"
                      id="cc-exp"
                      @input="cambia()"
                      type="text"
                      v-model="fechaExpiracion"
                      v-mask="'##/##'"
                    />
                  </div>
                  <div class="col-6">
                    <label>CVV</label>
                    <b-form-input
                      class="mb-1 control"
                      id="cc-csc"
                      type="password"
                      @input="cambia()"
                      v-model="cvv"
                      v-mask="'####'"
                    />
                  </div>
                </div>

                <div class="mt-1" v-if="formaElegida == 1">
                  <h5 class="mt-2">Datos de Facturación</h5>
                </div>

                <div class="row" v-if="formaElegida == 1">
                  <div class="col-12">
                    <label>Email</label>
                    <b-form-input
                      class="mb-1 control"
                      id="card_email"
                      placeholder="Email"
                      type="email"
                      @input="cambia()"
                      required
                      v-model="emailCreditCard"
                    />
                  </div>

                  <div class="col-12">
                    <label>Nombre</label>
                    <b-form-input
                      placeholder="Nombre"
                      class="mb-1 control"
                      id="cc-exp"
                      @input="cambia()"
                      type="text"
                      v-model="nombreCreditCard"
                    />
                  </div>
                  <div class="col-12">
                    <label>Apellido</label>
                    <b-form-input
                      class="mb-1 control"
                      id="cc-csc"
                      placeholder="Apellido"
                      type="text"
                      @input="cambia()"
                      v-model="apellidoCreditCard"
                    />
                  </div>

                  <div class="col-12">
                    <label>Telefono</label>
                    <b-form-input
                      placeholder="Telefono"
                      class="mb-1 control"
                      id="cc-exp"
                      @input="cambia()"
                      type="number"
                      min="0"
                      max="9"
                      v-model="telefonoCreditCard"
                    />
                  </div>
                  <div class="col-12">
                    <label>Direccion</label>
                    <b-form-input
                      class="mb-1 control"
                      id="cc-csc"
                      placeholder="Direccion"
                      type="text"
                      @input="cambia()"
                      v-model="direccionCreditCard"
                    />
                  </div>

                  <div class="col-6">
                    <label>Ciudad</label>
                    <b-form-input
                      placeholder="Ciudad"
                      class="mb-1 control"
                      id="cc-exp"
                      @input="cambia()"
                      type="text"
                      v-model="ciudadCreditCard"
                    />
                  </div>
                  <div class="col-6">
                    <label>Pais</label>
                    <b-form-input
                      class="mb-1 control"
                      id="cc-csc"
                      placeholder="Pais"
                      type="text"
                      @input="cambia()"
                      v-model="paisCreditCard"
                    />
                  </div>
                  <div class="col-6">
                    <label>Region</label>
                    <b-form-input
                      class="mb-1 control"
                      placeholder="Region"
                      id="cc-csc"
                      type="text"
                      @input="cambia()"
                      v-model="regionCreditCard"
                    />
                  </div>

                  <div class="col-6">
                    <label>Cod. Postal</label>
                    <b-form-input
                      placeholder="Cod Postal"
                      class="mb-1 control"
                      id="cc-exp"
                      @input="cambia()"
                      type="text"
                      v-model="codigoPostalCreditCard"
                    />
                  </div>
                  <div class="col-6">
                    <b-form-group>
                      <label>Tipo de Documento</label>
                      <v-select
                        v-model="tipoDocumentoCard"
                        :dir="dir"
                        label="text"
                        :options="documentOptions"
                        :reduce="(Item) => Item.value"
                        @input="elegirDocumento(tipoDocumentoCard)"
                        v-on:input="hideInputFormaPago()"
                        v-on:close="showInputFormaPago()"
                      />
                    </b-form-group>
                  </div>
                  <div class="col-6">
                    <label>N° de Doc.</label>
                    <b-form-input
                      class="mb-1 control"
                      id="cc-csc"
                      type="number"
                      min="0"
                      max="9"
                      @input="cambia()"
                      placeholder="Numero de doc."
                      v-model="documentoCard"
                    />
                  </div>
                </div>
              </div>
       
          </div>

          <div v-if="formaElegida == 4 && medioElegido == 1">
            <h5 class="mt-2">Datos para el pago</h5>
          </div>
          <div  v-if="formaElegida == 4 && medioElegido == 1">
            <div class="row">
              <div class="col-12">
                <label>Email</label>
                <b-form-input
                  class="mb-1"
                  type="email"
                  required
                  v-model="emailTransferenciaKushki"
                  @input="cambia()"
                  id="input-nombreTitular"
                />
                <b-form-group>
                  <label >Tipo de Documento</label>
                  <v-select
                    v-model="documentTypeTransfer"
                    :dir="dir"
                    label="text"
                    class="mb-1"
                    :options="documentOptions"
                    :reduce="(Item) => Item.value"
                    @input="elegirDocumento(documentTypeTransfer)"
                    v-on:input="hideInputFormaPago()"
                    v-on:close="showInputFormaPago()"
                  />
                </b-form-group>
                <label>N° de Doc.</label>
                <b-form-input
                  class="mb-1"
                  type="tel"
                  required
                  v-model="documentoTransfer"
                  @input="cambia()"
                  id="document-number"
                />
              </div>
            </div>
          </div>

          <div v-if="formaElegida == 3 && medioElegido == 1">
            <h5 class="mt-2">Datos para el pago</h5>
          </div>
          <div v-if="formaElegida == 3 && medioElegido == 1">
            <div class="row">
              <div class="col-6">
                <label>Nombre</label>
                <b-form-input
                  class="mb-1"
                  v-model="nombreEfectivoKushki"
                  @input="cambia()"
                  type="text"
                  id="input-nombreTitular"
                />
              </div>
              <div class="col-6">
                <label>Apellido</label>
                <b-form-input
                  class="mb-1"
                  v-model="apellidoEfectivoKushki"
                  @input="cambia()"
                  type="text"
                  id="input-nombreTitular"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label>N° de identificacion</label>
                <b-form-input
                  class="mb-1"
                  v-model="identificacionEfectivoKushki"
                  @input="cambia()"
                  type="number"
                  id="input-nombreTitular"
                />
              </div>
              <div class="col-6">
                <label>Email</label>
                <b-form-input
                  class="mb-1"
                  required
                  v-model="emailEfectivoKushki"
                  @input="cambia()"
                  type="email"
                  id="input-nombreTitular"
                />
              </div>
            </div>
          </div>

          <div class="row align-items-center termsAndconds">
            <div>
              <b-form-checkbox
                v-model="aceptoTerminosCondiciones"
                class="custom-control-info"
              >
                <label class="label-terms"
                  >Acepto los Términos y Condiciones</label
                >
              </b-form-checkbox>
            </div>
            <div>
              <b-button
                variant="link"
                class="p-0 ml-1 mt-0 ver-mas-button"
                @click="modalTermsShow = !modalTermsShow"
                v-b-modal.modal-terms
                >Ver mas</b-button
              >
            </div>
            <label
              v-if="terminos"
              class="font-italic"
              style="color: red !important"
              >Debe aceptar los Terminos y Condiciones</label
            >
          </div>

          <div class="row align-items-center">
            <div class="col">
              <div style="display: flex">
                <div class="button-container">
                  <b-button
                    id="button-pagar"
                    variant="primary"
                    @click="validateEmptyFields()"
                    disabled
                  >
                    Pagar {{moneda.code }} {{ this.total }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </div>
      <div id="loading-bg" v-if="loading">
        <div class="loading">
          <div class="effect-1 effects"></div>
          <div class="effect-2 effects"></div>
          <div class="effect-3 effects"></div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-terms"
      v-model="modalTermsShow"
      hide-header-close
      header-class="py-2"
      title="Términos y Condiciones"
      ok-only
      ok-title="Cerrar"
      centered
      size="xl"
      ref="modal-terms"
      scrollable
    >
      <div class="p-2">
        <span>
          {{ terminosCondiciones }}
        </span>
      </div>
    </b-modal>
  </div>
</template>

<script>

/* eslint-disable global-require */
import {
  BLink,
  BFormInput,
  BFormGroup,
  BButton,
  BForm,
  BImg,
  BCard,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAlert,
  BFormCheckbox,
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";
import draggable from "vuedraggable";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { mask } from "vue-the-mask";
import { backendUrl } from "@/config";
import axios from "axios";
import { Kushki } from "@kushki/js";
import { urlKushki } from "../../../config/index";
import { requestKushkiTokenCredit } from "./kushki/index";
import {
  siteUrl,
  ssl,
  enableLogs,
  celcomKushkiMerchantId,
  isProduction,
} from "../../../config/index";
import {
  StripeElementCard,
  StripeElementsPlugin,
} from "@vue-stripe/vue-stripe";

export default {
  components: {
    BLink,
    BFormInput,
    BButton,
    BForm,
    BImg,
    BCard,
    BAvatar,
    VuexyLogo,
    draggable,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ToastificationContent,
    BAlert,
    vSelect,
    mask,
    BFormCheckbox,
    StripeElementCard,
    BFormGroup
  },

  data() {
    return {
      codigoEnabled:false,
      hidePostalCode: true,
      token: null,
      charge: null,
      dataLinkActivo: {},
      fieldLinkActivo: [],
      dismissSecs: 5,
      dismissCountDown: 0,
      downImg: require("@/assets/images/pages/under-maintenance.svg"),
      logoProducto: "",
      nombreProducto: "",
      descripcionProducto: "",
      pasarelaString: "",
      medioString: "",
      list2: null,
      textoPromocional: "",
      nombreCliente: "",
      total: "",
      moneda: "",
      textMoneda: "",
      textMonedaArray: [],
      linkType: "",
      modalTermsShow: false,
      opciones: [],
      nombreTitular: "",
      numeroTarjeta: "",
      fechaExpiracion: "",
      mesExp: "",
      anioExp: "",
      cvv: "",
      emailTitular: "",
      inputsDynamicFormCompleted: false,
      dir: "ltr",
      medioElegido: "",
      inputsMedioPagoVisible: true,
      formasPago: [],
      loading: false,
      formaElegida: "",
      inputsFormaPagoVisible: false,
      modalTermsShow: false,
      mediosPago: [],
      metodoPago: [],
      pasarela: null,
      optionPasare: [],
      imagen: "",
      optionMedi: [],
      pasarelasSelector: [],
      pasarelaCheck: null,
      formaCheck: null,
      mediosPago2: [],
      periodicidad: "",
      textPeriodicidad: "",
      impuestos: 0,
      diasPrueba: "",
      clientSecret: "",
      cardObject: "",
      aceptoTerminosCondiciones: "",
      terminosCondiciones: "",
      codigo:""
    };
  },
  setup() {
    const optionMetodoPago = [
      { title: "Tarjeta de Credito", value: 1 },
      { title: "Tarjeta de Debito", value: 2 },
      { title: "Efectivo", value: 3 },
      { title: "Transferencia", value: 4 },
    ];
    const optionMediosPago = [
      { text: "Tarjeta de Crédito", value: 1, pasarela: [1, 2] },
      { text: "Tarjeta de Débito", value: 2, pasarela: [1] },
      { text: "Efectivo", value: 3, pasarela: [1] },
      { text: "Transferencia", value: 4, pasarela: [1] },
      { text: "Entel", value: 5, pasarela: [3] },
      { text: "Movistar", value: 6, pasarela: [3] },
    ];
    const documentOptions = [
      { text: "RUT", value: "RUT" },
      { text: "CC", value: "CC" },
      { text: "LIC. DE CONDUCIR", value: "LIC. DE CONDUCIR" },
    ];
    const optionPasarelas = [
      { text: "Kushki", value: 1 },
      { text: "Stripe", value: 2 },
      { text: "Suscripciones Móviles", value: 3 },
    ];
    return {
      optionMediosPago,
      optionMetodoPago,
      optionPasarelas,
      documentOptions,
    };
  },
  directives: { mask },

  created() {
    this.nombreProducto = JSON.parse(localStorage.getItem("productoNombre"));
    this.textoPromocional = JSON.parse(
      localStorage.getItem("textoPromocional")
    );
    this.mediosPago = JSON.parse(localStorage.getItem("mediosPago"));
    this.metodoPago = this.mediosPago;

    this.pasarela = JSON.parse(localStorage.getItem("pasarelas"));
    this.mediosPago = this.pasarela;


    for (let p = 0; p < this.pasarela.length; p++) {
      this.pasarelaString = this.pasarela[p] == 1 ? "Kushki" : "Stripe";
    }

    this.periodicidad = JSON.parse(localStorage.getItem("periodicidad"));
    this.descripcionProducto = JSON.parse(localStorage.getItem("descripcion"));
    this.total = JSON.parse(localStorage.getItem("importe"));
    this.moneda = JSON.parse(localStorage.getItem("moneda"));
    console.log('mone', this.moneda)
    this.terminosCondiciones = JSON.parse(localStorage.getItem("terms"));
    this.logoProducto = JSON.parse(localStorage.getItem("imgprev"));
    this.list2 = JSON.parse(localStorage.getItem("list2"));
    this.linkType = JSON.parse(localStorage.getItem("linkType"));
    this.textPeriodicidad = this.periodicidad.title
      // this.periodicidad == 1
      //   ? "Diaria"
      //   : this.periodicidad == 2
      //   ? "Semanal"
      //   : this.periodicidad == 3
      //   ? "Quincenal"
      //   : this.periodicidad == 4 
      //   ? "Mensual"
      //   : this.periodicidad == 5
      //   ? "Trimestral"
      //   : this.periodicidad == 6
      //   ? "Semestral"
      //   : "Anual";
    this.imagen = `${backendUrl}/link-pago/getImg/${this.logoProducto.replace(
      "/warehouse/",
      ""
    )}`;

    for (let k = 0; k < this.pasarela.length; k++) {
      this.optionPasare.push({
        id: this.pasarela[k],
        name:
          this.pasarela[k] == 1
            ? "Kushki"
            : this.pasarela[k] == 3
            ? "Suscripciones Móviles"
            : "Stripe",
      });
    }
   
    for (let k = 0; k < this.mediosPago.length; k++) {
      this.optionMedi.push({
        id: this.mediosPago[k],
        name:
          this.mediosPago[k] === 4
            ? "Transferencia"
            : this.mediosPago[k] ===1
            ? "Tarjeta de Credito"
            : this.mediosPago[k] ===3
            ? "Efectivo"
            : this.mediosPago[k] ===2
            ? "Tarjeta de Debito"
            : null,
      });
    }

  },
  methods: {
    showInputMedioPago() {
      this.inputsMedioPagoVisible = true;
    },
    hideInputMedioPago() {
      this.inputsMedioPagoVisible = false;
    },
    showInputFormaPago() {
      this.inputsFormaPagoVisible = true;
    },
    hideInputFormaPago() {
      this.inputsFormaPagoVisible = false;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },

    pasarelasSel(formaElegida) {
      
    },
    medioSel() {
      var me = this;
      var result = [];
      this.medioElegido = "";
      if (me.formaElegida != "") {
        result = me.optionPasarelas.filter((pasa) => {
          var bool = false;
          me.optionMediosPago.map((medios) => {
            if (
              this.mediosPago.includes(pasa.value) &&
              medios.pasarela.indexOf(pasa.value) > -1 &&
              me.formaElegida == medios.value
            ) {
              bool = true;
            }
          });
          if (bool) return pasa;
        });
      }
      this.pasarelasSelector = Object.assign([], result);
    },
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/under-maintenance-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
    formaSelector() {
      var me = this;
      var result = me.optionMediosPago.filter((medio) => {
        if (me.metodoPago.indexOf(medio.value) != -1) {
          return medio;
        }
      });

      return result;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.clearfix {
  clear: both;
  float: none;
}
.mb-1 form-control {
  padding: 20px;
  border: 1px solid #ccc;
}
.textPromo {
  text-align: left;
  display: block;
  font-size: 13px;
  line-height: 21px;
}

#card-monto {
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 8px;
  padding-top: 16px;
  margin-bottom: 30px;
}
.total-amount{
  font-size: 18px;
  font-weight: 600;
}
.card-shadow {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

#descripcion {
  text-align: left;
  display: block;
  font-size: 13px;
  line-height: 21px;
}

#button-pagar {
  margin-top: 24px;
  width: 100%;
}

#button-validar{
  width: 100%;
}

.bold {
  font-weight: bold;
}

#input-nombreTitular {
  text-transform: uppercase;
}

.size-span-selecciona-medio {
  font-size: 12px;
}

.termsAndconds {
  margin-left: 0%;
  margin-top: 8px;
}
.credit-card-data{
  background: rgba(0, 180, 200, .3);
  padding: 1%;
  border-radius: 20px;
}
.ver-mas-button {
  margin-bottom: 4px;
  font-weight: bolder;
  color: $info;
}
.textPromocional {
  text-align: left;
}

.avatarClass img {
  object-fit: contain !important;
}

.modal .modal-content {
  height: 540px;
}

.label-terms {
  font-weight: bolder;
  font-size: 14px;
}
@media (min-width:320px){
  .text-card-detail{
    width: 100%;
    text-align: center;
  }
  .button-container{
    width: 100%;
    justify-content: center;
  }
  #descripcion{
    width: 100%;
    text-align: center;
  }
}
@media (min-width:993px){
  .text-card-detail{
    width: 100%;
    text-align: left;
  }
  #descripcion{
    text-align: left;
  }
   .button-container{
     width: 100%;
     display: flex;
    justify-content: flex-end;

  }
  #button-pagar{

    width: 50%;
   
  }
}
</style>
